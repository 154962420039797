import { call, fork, takeEvery, all, put, select } from 'redux-saga/effects';
import { smsTemplatesAPI } from '../../../../helpers/api/smsTemplates';
// import { restoreWebsiteID } from '../../../../helpers/utility'; TODO fix current website ID
import { actions } from './actions';
import {
	messages,
	adaptSMSTemplate,
	adaptSMSTemplates,
	adaptSMSVariables,
	prepareSMSTemplate, prepareSMSTemplateUpdate, adaptSMSTemplatesList,
} from './utils';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import { toInteger } from 'lodash';

const getStore = ({ Settings, App }) => {
	const { SMS: { SMSTemplates } } = Settings;
	return {
		websiteID: App.get('websiteID'),
		baseData : SMSTemplates.get('baseData'),
		entities : SMSTemplates.get('entities'),
	};
};


function* listReload() {
	yield takeEvery(actions.RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID } = yield select(getStore);
		try {
			const params = {
				website_id: websiteID,
			};
			const res = yield call(smsTemplatesAPI.smsTemplates, params);
			if ( res && res.status === 200) {
				const entities = adaptSMSTemplates(res.data.data);
				const list = adaptSMSTemplatesList(res.data.data);
				yield put(actions.listRefresh(list));
				yield put(actions.entityRefresh(entities));
			}
		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* smsTemplateSave() {
	yield takeEvery(actions.DATA_SAVE, function* ({ data }) {
		const { closeModal, cb } = data;
		yield put(actions.uiRefresh({ loading: true }));
		const { baseData, websiteID } = yield select(getStore);
		try {
			const params = {
				website_id: websiteID,
			};
			const preparedData = prepareSMSTemplate(baseData);
			const res = yield call(smsTemplatesAPI.createSmsTemplates, preparedData, params);
			if ( res && res.status === 200) {
				const adapted = adaptSMSTemplate(res.data.data);
				yield put(actions.baseDataRefresh(adapted));
			}
			yield put(actions.uiRefresh({ loading: false, visible: !closeModal, editMode: true }));
			yield put(actions.listReload());
			if (closeModal) {
				yield put(actions.baseDataReset());
			}
			showSuccess(messages.successSaveTemplate);
			if (typeof cb === 'function') cb();
		} catch (e) {
			showError(messages.errorListReload, e);
			yield put(actions.uiRefresh({ loading: false }));
			logger.log(e);
		}

	});
}

function* smsTemplateUpdate() {
	yield takeEvery(actions.DATA_UPDATE, function* ({ data }) {
		const { closeModal, cb } = data;
		yield put(actions.uiRefresh({ loadEntity: true }));
		const { baseData, entities, websiteID } = yield select(getStore);
		try {
			const params = {
				website_id: websiteID,
			};
			const preparedData = prepareSMSTemplateUpdate(baseData);
			const smsTemplateID = toInteger(preparedData.id);

			const res = yield call(smsTemplatesAPI.updateSmsTemplates, smsTemplateID, preparedData, params);
			if ( res && res.status === 200) {
				const adapted = adaptSMSTemplate(res.data.data);
				entities[smsTemplateID] = adapted;

				yield put(actions.baseDataRefresh(adapted));
				yield put(actions.entityRefresh(entities));

			}
			yield put(actions.uiRefresh({ loadEntity: false, visible: !closeModal, editMode: true }));
			// yield put(actions.listReload());

			if (closeModal) {
				yield put(actions.baseDataReset());
			}
			if (typeof cb === 'function') cb();
			showSuccess(messages.successUpdateTemplate);

		} catch (e) {
			showError(messages.errorListReload, e);
			yield put(actions.uiRefresh({ loadEntity: false }));
			logger.log(e);
		}

	});
}


function* smsVariablesReload() {
	yield takeEvery(actions.SMS_VARIABLES_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(smsTemplatesAPI.getSMSVariables);
			if ( res && res.status === 200) {
				const adapted = adaptSMSVariables(res.data.data);
				yield put(actions.variablesRefresh(adapted));
			}
		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* getByID() {
	yield takeEvery(actions.GET_BY_ID, function* ({ data }) {
		yield put(actions.uiRefresh({ loadEntity: true }));
		try {
			const res = yield call(smsTemplatesAPI.smsTemplatesByID, data );
			if ( res && res.status === 200) {
				const adapted = adaptSMSTemplate(res.data.data);
				yield put(actions.baseDataRefresh(adapted));
			}
		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		yield put(actions.uiRefresh({ loadEntity: false }));
	});
}

function* deleteByID() {
	yield takeEvery(actions.DELETE_BY_ID, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			yield call(smsTemplatesAPI.deleteByID, data );
			yield put(actions.listReload());

		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}


export default function* createSmsGatewaysSaga() {
	yield all([
		fork(listReload),
		fork(smsVariablesReload),
		fork(smsTemplateSave),
		fork(smsTemplateUpdate),
		fork(getByID),
		fork(deleteByID),
	]);
}
