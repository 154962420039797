const actions = {

	RTM_DEPOSITS_LIST_RELOAD             : 'RTM_DEPOSITS_LIST_RELOAD',
	RTM_DEPOSITS_DATA_REFRESH            : 'RTM_DEPOSITS_DATA_REFRESH',
	RTM_DEPOSITS_NEW_DEPOSIT_LIST_REFRESH: 'RTM_DEPOSITS_NEW_DEPOSIT_LIST_REFRESH',

	RTM_DEPOSITS_FILTER_RESET  : 'RTM_DEPOSITS_FILTER_RESET',
	RTM_DEPOSITS_FILTER_REFRESH: 'RTM_DEPOSITS_FILTER_REFRESH',
	RTM_DEPOSITS_FILTER_APPLY  : 'RTM_DEPOSITS_FILTER_APPLY',

	RTM_DEPOSITS_UI_REFRESH: 'RTM_DEPOSITS_UI_REFRESH',

	RTM_DEPOSITS_SOCKET_SUBSCRIBE_TO_UPDATE  : 'RTM_DEPOSITS_SOCKET_SUBSCRIBE_TO_UPDATE',
	RTM_DEPOSITS_SOCKET_NOTIFY_CREATE_DEPOSIT: 'RTM_DEPOSITS_SOCKET_NOTIFY_CREATE_DEPOSIT',
	RTM_DEPOSITS_SOCKET_NOTIFY_UPDATE_DEPOSIT: 'RTM_DEPOSITS_SOCKET_NOTIFY_UPDATE_DEPOSIT',

	RTM_DEPOSITS_ORDERED_IDS_REFRESH: 'RTM_DEPOSITS_ORDERED_IDS_REFRESH',

	listReload: () => ({
		type: actions.RTM_DEPOSITS_LIST_RELOAD,
	}),

	orderedIDsRefresh: (ids = []) => ({
		type: actions.RTM_DEPOSITS_ORDERED_IDS_REFRESH,
		data: ids,
	}),

	dataRefresh: (depositIDs, entities, depositTotals) => ({
		type: actions.RTM_DEPOSITS_DATA_REFRESH,
		data: {
			depositIDs,
			entities,
			depositTotals,
		},
	}),
	newDepositListRefresh: (newDepositIDs, newDepositEntities) => ({
		type: actions.RTM_DEPOSITS_NEW_DEPOSIT_LIST_REFRESH,
		data: {
			newDepositIDs,
			newDepositEntities,
		},
	}),

	filterReset: () => ({
		type: actions.RTM_DEPOSITS_FILTER_RESET,
	}),
	filterRefresh: data => ({
		type: actions.RTM_DEPOSITS_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.RTM_DEPOSITS_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.RTM_DEPOSITS_UI_REFRESH,
		data,
	}),

	notifyCreateDeposit: data => ({
		type: actions.RTM_DEPOSITS_SOCKET_NOTIFY_CREATE_DEPOSIT,
		data,
	}),
	notifyUpdateDeposit: data => ({
		type: actions.RTM_DEPOSITS_SOCKET_NOTIFY_UPDATE_DEPOSIT,
		data,
	}),
	subscribeToUpdate: depositIDs => ({
		type: actions.RTM_DEPOSITS_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			depositIDs,
		},
	}),
};

export default actions;
